<template>
  <div>
    <Pane :bottom="0">
      <a-tabs v-model="activeKey" :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in taskCategoryList" :key="item.value" :tab="item.name"></a-tab-pane>
        <a-tab-pane key="send" tab="已发"></a-tab-pane>
        <a-tab-pane key="notify_task-0" tab="待阅"></a-tab-pane>
        <a-tab-pane key="notify_task-1" tab="已阅"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-button
            @click="$router.push($route.path + '/transfer')"
            v-if="$getPermission($route.path + '/transfer')"
            type="primary"
          >任务迁移</a-button>
        </a-space>
      </template>
    </Pane>

    <a-card class="container">
      <a-form layout="inline" :colon="false" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input placeholder="事项" v-decorator="['title']" />
        </a-form-item>


             <a-form-item v-if="this.activeKey !== 'send'">

          <EmployeeSelector
            :single="true"
            @change="
              (arr) => {
                if (arr.length > 0) {
                  user = arr[0];
                } else {
                  user = {};
                }
              }
            "
            :value="user.name ? [user] : []"
          >
            <a-button style="text-align:left;width: 180px">
              <span v-if='user.name'>{{user.name}}</span>
              <span v-else style="color: rgba(0, 0, 0, 0.35)">申请人</span>
            </a-button>
          </EmployeeSelector>
        </a-form-item>

        <a-form-item>
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            style="width: 180px"
            v-decorator="['taskType']"
            placeholder="审批类型"
          >
            <a-select-option
              v-for="item in taskTypeList"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-space>
            <a-button type="primary" html-type="submit">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>

      <Padding />

      <a-table
        :data-source="taskList"
        :loading="loading"
        bordered
        @change="onChange"
        :pagination="{
          current,
          pageSize,
          total,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :scroll="scroll"
        rowKey="taskId"
      >
        <a-table-column title="事项" ellipsis="true">
          <template slot-scope="text">
            <div style="padding: 6px">
              <a-tooltip title="右键打开新页面">
                <a
                  href="#"
                  @click.prevent="route(text)"
                  @contextmenu.prevent="route(text, 'right')"
                >{{ text.title }}</a>
              </a-tooltip>
            </div>
          </template>
        </a-table-column>
        <a-table-column title="所属模块" data-index="processDefinitionKey" width="100px" align="center">
          <template slot-scope="processDefinitionKey">
            <DataDictFinder dictType="reviewModule" :dictValue="processDefinitionKey" />
          </template>
        </a-table-column>

        <a-table-column title="申请人" data-index="applicant" width="100px" align="center"></a-table-column>
        <a-table-column title="部门" data-index="department" width="180px"></a-table-column>

        <a-table-column title="受托人" width="100px" align="center">
          <template slot-scope="text">
            <span>{{text.userName ? text.userName : '--' }}</span>
          </template>
        </a-table-column>
        <a-table-column title="待办人" width="100px" align="center">
          <template slot-scope="text">
            <span>{{ text.nextAssignee ? text.nextAssignee : '--' }}</span>
          </template>
        </a-table-column>

        <a-table-column title="任务状态" align="center" width="100px">
          <template slot-scope="text">
            <DataDictFinder
              dictType="taskStatus"
              :dictValue="String(text.status)"
              iconType="badge"
              badgeType="color"
            />
          </template>
        </a-table-column>

        <a-table-column title="流程状态" align="center" width="100px">
          <template slot-scope="text">
            <DataDictFinder
              dictType="taskStatus"
              :dictValue="String(text.procStatus)"
              iconType="badge"
              badgeType="color"
            />
          </template>
        </a-table-column>

        <a-table-column title="开始时间" data-index="startTime" align="center" width="160px"></a-table-column>
        <a-table-column title="结束时间" data-index="endTime" align="center" width="160px"></a-table-column>
        <a-table-column title="提交时间" data-index="procStartTime" align="center" width="160px"></a-table-column>

        <a-table-column title="操作" align="right" width="120px" fixed="right">
          <template slot-scope="text">
            <a-space>
              <router-link :to="getUrl(text)" v-if="text.status === 'RESUBMITED'">重新提交</router-link>
              <a-tooltip title="右键打开新页面" v-else>
                <a
                  href="#"
                  @click.prevent="route(text)"
                  @contextmenu.prevent="route(text, 'right')"
                >
                  <span
                    v-if="
                      text.status === 'ASSIGNED' &&
                      activeKey &&
                      activeKey.indexOf('notify_task') === -1
                    "
                  >去审核</span>
                  <span v-else>查看</span>
                </a>
              </a-tooltip>

              <a-dropdown v-if="activeKey === 'RUNNING' || activeKey === 'COMPLETED'">
                <a @click="(e) => e.preventDefault()">
                  更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-if="activeKey === 'RUNNING'">
                    <a href="#" class="danger" @click.prevent="removeText(text)">终止</a>
                  </a-menu-item>
                  <a-menu-item v-if="activeKey === 'COMPLETED'">
                    <a href="#" class="danger" @click.prevent="takeBack(text)">取回</a>
                  </a-menu-item>
                  <a-menu-item
                    v-if="activeKey === 'COMPLETED' && text.procStatus !== 'COMPLETED' && text.procStatus !== 'CANCELLED'"
                  >
                    <a href="#" class="danger" @click.prevent="removeText(text)">终止</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      title="终止原因"
      :visible="stopVisible"
      @cancel="cancel"
      @ok="ok"
      :okButtonProps="{
        props: {
          loading: stopLoading,
        },
      }"
    >
      <a-textarea v-model="stopReason" placeholder="请填写" />
    </a-modal>
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import { fetchTask, read, takeBackTask, done } from "@/api/approval";
import { mapGetters } from "vuex";

import EmployeeSelector from "@/components/employee-selector";


export default {
  name: "approvalTask",

  mixins: [screen, watermark],

  components: {
    EmployeeSelector
  },

  data() {
    return {
      activeKey: null,
      form: this.$form.createForm(this),
      params: {},
      taskType: null,
      loading: false,
      current: 1,
      pageSize: 10,
      taskList: [],
      total: 0,

      activeText: {},
      stopVisible: false,
      stopReason: "",

      stopLoading: false,

      user: {}
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    taskCategoryList() {
      return this.findDataDict("task_running_completed");
    },

    taskTypeList() {
      return this.findDataDict("taskType");
    }
  },

  activated() {
    const { query } = this.$route;
    const { taskNotify } = query || {};

    if (taskNotify) {
      this.activeKey = "notify_task-0";
    } else if (!this.activeKey) {
      // 第一次进入审批任务列表页面，没选中 tab，设置默认值
      this.activeKey = this.taskCategoryList[0].value;
    } else {
      // 已经进入过审批任务列表页面，这时候只需要重新获取一下数据即可
      this.getList();
    }
  },

  watch: {
    activeKey(newValue) {
      if (newValue) {
        this.current = 1;
        this.pageSize = 10;
        this.getList();
      }
    }
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize, taskType, activeKey } = this;
      const params = {
        pageNum: current,
        pageSize,
        title: this.params.title,
        applicant: this.user.userId,
      };
      if (taskType) {
        params.taskType = taskType;
      }

      if (activeKey) {
        if(activeKey == "send") {
            const user = JSON.parse(localStorage.getItem("user"));
            params.status = "COMPLETED";
            params.applicant = user.uuid;
        } else {
          const arr = activeKey.split("-");
          if (arr.length > 1) {
            params.taskNotify = arr[0];
            params.status = this.taskCategoryList[arr[1]].value;
          } else {
            params.status = activeKey;
          }
        }
      }

      fetchTask(params)
        .then(res => {
          if (Array.isArray(res.list)) {
            this.taskList = res.list;
            console.log(this.taskList);

            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.taskType = values.taskType;
          this.params = {
            title: values.title
          };
          this.current = 1;
          this.pageSize = 10;
          this.getList();
        }
      });
    },

    // 待重新提交时点击跳转到指定的页面重新做
    getUrl(text, mouse = "left") {
      if (mouse === "right") {
        return (
          window.location.origin +
          "#" +
          `${this.$route.path}/detail?taskId=${text.taskId}&instanceId=${text.processInstanceId}`
        );
      }
      return `${this.$route.path}/detail?taskId=${text.taskId}&instanceId=${text.processInstanceId}`;

      // console.log("text", text);
      // if (text.status === "RESUBMITED") {
      //   if (text.processDefinitionKey === "con_register") {
      //     // 跳转到合同登记页面
      //     const contractId = text.businessKey
      //       ? text.businessKey.substring(3)
      //       : "";

      //     return `/produce/contract-register/add?id=${contractId}`;
      //   }
      //   if (text.processDefinitionKey === "con_configure") {
      //     // 跳转到合同编辑页面
      //     const contractId = text.businessKey
      //       ? text.businessKey.substring(3)
      //       : "";
      //     return `/produce/contract/edit?id=${contractId}`;
      //   } else if (text.processDefinitionKey === "prj_phase_progress") {
      //     let id = text.businessKey
      //       ? text.businessKey.substring(3).split(",")[0]
      //       : "";
      //     return `/produce/progress/detail?id=${id}`;
      //   } else if (text.processDefinitionKey === "fin_invoice") {
      //     return `/finance/invoice/apply?taskId=${text.taskId}&instanceId=${text.processInstanceId}`;
      //   } else if (text.processDefinitionKey === "prj_configure") {
      //     // 产值分配审批
      //     let id = text.businessKey
      //       ? text.businessKey.substring(3).split(",")[0]
      //       : "";
      //     return `/produce/progress/allocate?id=${id}`;
      //   } else if (text.processDefinitionKey === "arc_borrow") {
      //     // 是否是档案出借审批
      //     const id = text.businessKey ? text.businessKey.substring(3) : "";
      //     return `/oa/archive/query/lend?id=${id}`;
      //   } else if (text.processDefinitionKey === "arc_transfer") {
      //     // 是否是档案移交审批
      //     const id = text.businessKey ? text.businessKey.substring(3) : "";
      //     return `/oa/archive/query/transfer?id=${id}`;
      //   } else if (text.processDefinitionKey === "ofc_inv_apply") {
      //     // 是否是酒水审批
      //     const id = text.businessKey ? text.businessKey.substring(3) : "";
      //     return `/oa/material/apply?id=${id}`;
      //   } else {
      //     return "";
      //   }
      // } else {
      //   return `${this.$route.path}/detail?taskId=${text.taskId}&instanceId=${text.processInstanceId}`;
      // }
    },

    route(text, mouse = "left") {
      // 如果是待阅任务，并且视图是待阅任务，已读它
      if (
        text.taskDefinitionKey === "notify_task" &&
        this.activeKey === "notify_task-0"
      ) {
        read({
          taskId: text.taskId
        });
      }
      if (mouse === "right") {
        window.open(this.getUrl(text, "right"), "_blank");
        return;
      }
      this.$router.push(this.getUrl(text));
    },

    // 已办的任务可以取回
    takeBack(text) {
      const that = this;
      this.$confirm({
        title: "确定要取回这条任务吗？",
        onOk() {
          takeBackTask({
            taskId: text.taskId
          }).then(() => {
            that.getList();
          });
        }
      });
    },
    reset() {
      this.form.resetFields();
      this.taskType = null;
      this.current = 1;
      this.pageSize = 10;
      this.params = {};
      this.user = {}
      this.getList();
    },

    removeText(text) {
      this.activeText = text;
      this.stopVisible = true;
    },
    cancel() {
      this.stopVisible = false;
      this.stopReason = "";
    },
    ok() {
      if (!this.stopReason) {
        this.$message.error("请输入终止原因");
        return;
      }

      this.stopLoading = true;
      done({
        taskId: this.activeText.taskId,
        taskDefinitionKey: this.activeText.taskDefinitionKey,
        map: {
          operation: "CANCELLED",
          comment: this.stopReason
        }
      })
        .then(() => {
          this.cancel();
          this.getList();
        })
        .finally(() => {
          this.stopLoading = false;
        });
    }
  }
};
</script>